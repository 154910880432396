<template>
  <div class="dashboard-editor-container">
    <el-row :gutter="32">
      <el-col :xs="24" :sm="12" :lg="4">
        <div class="chart-wrapper">
          <div class="card-panel-icon-wrapper el-icon-notebook-2 icon-apply"></div>
          <div class="card-panel-description">
            <p class="card-panel-text">注册会员</p>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.todayRegisterSum?indexObj.todayRegisterSum:'0'}}</p>
              <p>今日：</p>
            </div>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.registerSum?indexObj.registerSum:'0'}}</p>
              <p>累计：</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :lg="4">
        <div class="chart-wrapper">
          <div class="card-panel-icon-wrapper iconfont icon-weijizhijinggao icon-critical"></div>
          <div class="card-panel-description">
            <p class="card-panel-text">加群数量</p>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.todayGroupSum?indexObj.todayGroupSum:'0'}}</p>
              <p>今日：</p>
            </div>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.groupSum?indexObj.groupSum:'0'}}</p>
              <p>累计：</p>
            </div>
          </div>
        </div>

      </el-col>
      <el-col :xs="24" :sm="12" :lg="4">
        <div class="chart-wrapper">
          <div class="card-panel-icon-wrapper iconfont icon-baogao-copy icon-report"></div>
          <div class="card-panel-description">
            <p class="card-panel-text">加群人数</p>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.todayPeopleSum?indexObj.todayPeopleSum:'0'}}</p>
              <p>今日：</p>
            </div>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.groupPeopleSum?indexObj.groupPeopleSum:'0'}}</p>
              <p>累计：</p>
            </div>
          </div>
        </div>

      </el-col>
      <el-col :xs="24" :sm="12" :lg="4">
        <div class="chart-wrapper">
          <div class="card-panel-icon-wrapper iconfont icon-biaobenbiaobenjinyangbiaobenjianceshiguan icon-specimen">
          </div>
          <div class="card-panel-description">
            <p class="card-panel-text">开通会员人数</p>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.todayVipSum?indexObj.todayVipSum:0}}</p>
              <p>今日：</p>
            </div>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.vipSum?indexObj.vipSum:0}}</p>
              <p>累计：</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :lg="4">
        <div class="chart-wrapper">
          <div class="card-panel-icon-wrapper iconfont icon-biaobenbiaobenjinyangbiaobenjianceshiguan icon-specimen">
          </div>
          <div class="card-panel-description">
            <p class="card-panel-text">支付金额</p>
            <div class="card-panel-name">
              <p class="card-panel-num">{{(indexObj.todayVipMoneySum?indexObj.todayVipMoneySum:0)/100}}</p>
              <p>今日：</p>
            </div>
            <div class="card-panel-name">
              <p class="card-panel-num">{{(indexObj.vipMoneySum?indexObj.vipMoneySum:0)/100}}</p>
              <p>累计：</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :lg="4">
        <div class="chart-wrapper">
          <div class="card-panel-icon-wrapper iconfont icon-biaobenbiaobenjinyangbiaobenjianceshiguan icon-specimen">
          </div>
          <div class="card-panel-description">
            <p class="card-panel-text">当前会员人数</p>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.todayVip}}</p>
              <p>今日：</p>
            </div>
            <div class="card-panel-name">
              <p class="card-panel-num">{{indexObj.vipSum}}</p>
              <p>累计：</p>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="real-time">
      <div class="real-box">
        <div class="real-tit">
          <h6>加群榜</h6>
        </div>
        <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
          :data="grouptabledata" :height="tableHeight" border style="width: 100%">
          <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
          <el-table-column prop="groupArea" label="排名" align="center"></el-table-column>
          <el-table-column prop="groupArea" label="群名称" align="center"></el-table-column>
          <el-table-column prop="groupArea" label="人数" align="center"></el-table-column>
          <el-table-column prop="groupArea" label="时间" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="real-box">
        <div class="real-tit">
          <h6>消费榜</h6>
        </div>
        <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
          :data="grouptabledata" :height="tableHeight" border style="width: 100%">
          <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
          <el-table-column prop="groupArea" label="排名" align="center"></el-table-column>
          <el-table-column prop="groupArea" label="群名称" align="center"></el-table-column>
          <el-table-column prop="groupArea" label="人数" align="center"></el-table-column>
          <el-table-column prop="groupArea" label="时间" align="center"></el-table-column>
        </el-table>
      </div>

    </div>

  </div>
</template>

<script>
  //import criticalchart from './criticalChart.vue';
  //import mapwrapper from './Mapwrapper.vue'
  import {
    indexpagedata
  } from "@/api/api";

  export default {
    name: '',
    components: {
      //criticalchart,
      //mapwrapper
    },
    props: {},
    data() {
      return {
        yearchannel: null,
        grouptabledata:[],
        tableHeight: window.innerHeight - 280,
        indexObj:{}
      }
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)

      indexpagedata({}).then((res) => {
        console.log(res)
        if(res.status==200){
          this.indexObj = res.data
        }else{
          this.$message.error(res.message);
        }

      }).catch((error) => {
        this.$message.error("获取数据失败！");
      });


    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      getHeight() {
        this.tableHeight = window.innerHeight - 235;
      },

    }
  }
</script>

<style scoped>
  .real-tit {
    width: 100%;
    height: 40px;

  }

  .card-panel-name {
    width: 100%;
    height: 30px;
  }

  .card-panel-name p {
    float: right;
  }

  .card-panel-name p:nth-child(1) {

    color: #36a3f7;
    float: right;
    line-height: 30px;
  }

  .card-panel-name p:nth-child(2) {
    line-height: 30px;
    font-size: 14px;
    color: #777;
  }

  .real-tit h6 {
    float: left;
    line-height: 40px;
    font-size: 16px;
    color: #222;

  }

  .real-time {
    border-radius: 4px;
    overflow: hidden;
  }
  .real-box{
    width: calc(50% - 40px);
    float: left;
    background: #fff;
    padding: 6px 15px;

  }
  .real-box:nth-child(2){
    float: right;
  }
  .echarts-map {
    width: 90%;
    height: 500px;
    margin: 0 auto;
    background: #ccc;
  }

  .dashboard-editor-container {
    padding: 16px;
    position: relative;
  }

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 6px 6px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;
  }

  .card-panel-icon-wrapper {
    float: left;
    padding: 10px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
    border-radius: 6px;
    -webkit-transition: all .38s ease-out;
    transition: all .38s ease-out;
  }

  .icon-apply {
    color: #40c9c6;
    font-size: 48px;
  }

  .icon-critical {
    color: #f4516c;
    font-size: 52px;
  }

  .icon-report {
    color: #36a3f7;
    font-size: 44px;
  }

  .icon-specimen {
    color: #34bfa3;
    font-size: 48px;
  }

  .card-panel-description {
    float: right;
  }

  .card-panel-text {
    line-height: 18px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: bold;
    margin-top: 0px;
    text-align: right;
  }

  .card-panel-num {
    font-size: 20px;
    color: #666;
    font-weight: bold;
    text-align: right;
  }

  .chart-wrapper:hover .icon-apply {
    color: #fff;
    background: #40c9c6;
  }

  .chart-wrapper:hover .icon-critical {
    color: #fff;
    background: #f4516c;
  }

  .chart-wrapper:hover .icon-report {
    color: #fff;
    background: #36a3f7;
  }

  .chart-wrapper:hover .icon-specimen {
    color: #fff;
    background: #34bfa3;
  }

  @media (max-width:1024px) {
    .chart-wrapper {
      padding: 8px;
    }
  }





  .el-col {
    border-radius: 4px;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  .bg-purple {
    background: #d3dce6;
  }

  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
</style>
